import { computed } from "vue";
import { useRoute } from "vue-router";

export function usePatientRoute() {
  const route = useRoute();

  const zisNumber = computed(() => getNumberFromRoute());

  function getNumberFromRoute() {
    if (typeof route.params.zis_number !== "string") {
      throw new Error("Zis number in route is not a string.");
    }
    if (Number.isNaN(route.params.zis_number)) {
      throw new Error("Zis number in route is not a number.");
    }
    if (Number(route.params.zis_number) < 0) {
      throw new Error("Zis number in route is not positive.");
    }
    return Number(route.params.zis_number);
  }

  return {
    zisNumber,
  };
}
export function usePossiblePatientRoute() {
  const route = useRoute();

  const zisNumber = computed((): number | undefined => getNumberFromRoute());

  function getNumberFromRoute() {
    if (typeof route.params.zis_number === "undefined") {
      return undefined;
    }
    if (typeof route.params.zis_number !== "string") {
      throw new Error("Zis number in route is not a string.");
    }
    if (Number.isNaN(route.params.zis_number)) {
      throw new Error("Zis number in route is not a number.");
    }
    if (Number(route.params.zis_number) < 0) {
      throw new Error("Zis number in route is not positive.");
    }
    return Number(route.params.zis_number);
  }

  return {
    zisNumber,
  };
}
